import { buyShopOffer } from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import type { ShopOffer } from '@patrianna/shared-patrianna-types/store/ShopModule'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'

import { useAppDispatch } from 'store/hooks'
import { openDialog } from 'store/modules/dialog/actions'
import { redeemFlowStart } from 'store/modules/redeem/actions'
import ROUTES from 'temp/routes.json'

export type VerificationFromProps = 'redeem' | 'payment' | 'account'

type UseReturnAfterVerificationProps = {
  handleOpenSubmitDocumentsDialogAfterClosingVerificationDialog: () => void
  handleOpenAfterClosingSubmitDocumentsDialog: (verificationFrom?: VerificationFromProps | string) => void
  handleSetVerificationFromToParams: (verificationFrom: VerificationFromProps | string) => void
  handleRemoveVerificationFromParams: () => void
  getVerificationFrom: () => string | null
}

export const OFFER_CODE_VERIFICATION = 'TEMP_OFFER_VERIFICATION'
export const VERIFICATION_FROM_PARAM = 'verificationFrom'

export const useReturnAfterVerification = (): UseReturnAfterVerificationProps => {
  const dispatch = useAppDispatch()
  const { push, replace } = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const getVerificationFrom = (): string | null =>
    new URLSearchParams(searchParams)?.get(VERIFICATION_FROM_PARAM) || null

  const handleSetVerificationFromToParams = (verificationFrom: VerificationFromProps | string) => {
    if (verificationFrom) {
      const newSearchParams = new URLSearchParams(searchParams)

      newSearchParams?.set(VERIFICATION_FROM_PARAM, verificationFrom)
      replace(`${pathname}?${newSearchParams?.toString()}`)
    }
  }

  const handleRemoveVerificationFromParams = () => {
    if (searchParams.has(VERIFICATION_FROM_PARAM)) {
      const newSearchParams = new URLSearchParams(searchParams)

      newSearchParams?.delete(VERIFICATION_FROM_PARAM)

      const query = newSearchParams?.toString()

      replace(query ? `${pathname}?${query}` : pathname)
    }
  }

  const handleOpenSubmitDocumentsDialogAfterClosingVerificationDialog = () => {
    const verificationFrom = getVerificationFrom()

    if ((verificationFrom && verificationFrom !== 'account') || !verificationFrom) {
      return dispatch(openDialog({ modalName: 'SUBMIT_DOCUMENTS_DIALOG', dialogProps: { verificationFrom } }))
    }

    if (verificationFrom && verificationFrom === 'account') {
      handleRemoveVerificationFromParams()
    }
  }

  const handleOpenAfterClosingSubmitDocumentsDialog = (verificationStartFrom?: VerificationFromProps | string) => {
    const verificationFrom = getVerificationFrom() || verificationStartFrom

    if (verificationFrom) {
      if (verificationFrom === 'redeem') {
        handleRemoveVerificationFromParams()

        return dispatch(redeemFlowStart(push))
      }

      const offerCode = sessionStorage?.getItem(OFFER_CODE_VERIFICATION) || null // [FYI]: offerCode set up on payment dialog

      if (verificationFrom === 'payment' && offerCode) {
        handleRemoveVerificationFromParams()

        return dispatch(buyShopOffer({ offer: { code: offerCode } as ShopOffer, routerPush: push }))
      }

      if (verificationFrom === 'account') {
        handleRemoveVerificationFromParams()

        push(ROUTES.MY_PROFILE)
      }
    }
  }

  return {
    handleOpenSubmitDocumentsDialogAfterClosingVerificationDialog,
    handleOpenAfterClosingSubmitDocumentsDialog,
    handleSetVerificationFromToParams,
    handleRemoveVerificationFromParams,
    getVerificationFrom,
  }
}
