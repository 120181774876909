import { setClarity } from '@patrianna-payments/analytics/clarity/events'
import type { PurchaseMethod } from '@patrianna/shared-patrianna-types/store/PaymentsFlowModule'
import * as Sentry from '@sentry/nextjs'

import {
  fetchSavedCards,
  getAvailablePaymentProvidersRequest,
  repeatPaymentAfterErrorByProvider,
  setIsInsufficientFundsOffersAvailable,
  setIsPaymentFlowInProcess,
  setQuickPurchaseStage,
  showResquePaymentMethod,
} from '@patrianna-payments/shared-store/payments/store/actions'
import {
  paymentErrorsHandler,
  quickPurchasePaymentErrorsHandler,
} from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import {
  getPaymentMethodsSelector,
  getPurchaseProvidersSelector,
} from '@patrianna-payments/shared-store/payments/store/selectors'
import type {
  BuyShopOfferProps,
  CreateOrderResponseCardPaymentErrorProps,
  ThreeDSData,
} from '@patrianna-payments/shared-store/payments/store/types'
import { WithdrawMethodType } from '@patrianna-payments/shared-store/redeem/types/WithdrawMethodType'
import { skipOtpSelectorWithMandatoryAndClosed } from '@patrianna-payments/shared-store/skip-otp'
import type { OfferPurchaseType } from '@patrianna/shared-patrianna-types/store/ShopModule'
import type { OfferDeclineNotification } from '@patrianna/shared-patrianna-types/websocket/notifications'
import type { GetPaymentOrderRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { ConfirmOrderResponse, CreateOrderResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import type { WSResponse } from '@patrianna/shared-patrianna-types/websocket/types'
import { detectMobile } from '@patrianna/shared-utils'
import { trackClickOnOffer, trackSuccessPayment } from 'analyticActions/common'
import { trackFirstPurchase } from 'analyticActions/common/trackFirstPurchase'
import { trackEvent } from 'config/analytic'
import RepeatPaymentSnackbar from 'containers/GlobalSnackbar/RepeatPaymentSnackbar'
import type { RescueProviders } from 'dialogs/PaymentDialog/PaymentWays/type'
import { ApplePayGooglePayProviders } from 'dialogs/PaymentDialog/PaymentWays/type'
import { OFFER_CODE_VERIFICATION } from 'hooks/useReturnAfterVerification'
import { getSeonSessionId } from 'services/seon'
import type { TypedThunk } from 'src/store'
import { sweepstakeEnabledSelector } from 'src/store/modules/appConfig/selectors'
import { setShowDailyBonusCaptcha } from 'store/modules/bonusesFlow/slice'
import { closeAllDialogs, openDialog, removeDialogByName, replaceDialog } from 'store/modules/dialog/actions'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'
import { getOffers } from 'store/modules/shop/actions'
import { getOfferByCodeSelector, purchaseLimitsSelector } from 'store/modules/shop/selectors'
import { clearSlotGameFlow } from 'store/modules/slotGameFlow/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { getAccountInfo, getLiveChatSettingsRequest, setFirstDepositDate } from 'store/modules/user/actions'
import {
  getLiveChatSettingsSelector,
  getUserAccountIdSelector,
  getUserHashSelector,
  getUserRestrictionsSelector,
  getUserSelector,
  isLoggedInSelector,
} from 'store/modules/user/selectors'
import type { GetPaymentOrderResponseBody } from 'temp/payment/client'
import ROUTES from 'temp/routes.json'
import { getDataFromSessionStorage, OFFER_TEMP, removeDataFromSessionStorage } from 'utils/sessionStorage'

const clearPaymentState = (): TypedThunk => (dispatch) => {
  dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
  dispatch(removeDialogByName({ modalName: 'PAYMENT_DIALOG' }))
  dispatch(removeDialogByName({ modalName: 'PAYMENT_CVV_DIALOG' }))
  dispatch(removeDialogByName({ modalName: 'PAYMENT_THREE_DS_DIALOG' }))
  window.history.replaceState({}, document.title, window.location.href.replace(window.location.search, ''))
}

const showRescueProviders =
  (
    code: string,
    errorMessage: string = 'Payment has failed',
    providers: string[],
    loadPaymentData = true
  ): TypedThunk =>
  (dispatch) => {
    const isMobile = detectMobile()
    dispatch(removeDialogByName({ modalName: 'ORDER_CONFIRMATION_MESSAGE' }))
    dispatch(removeDialogByName({ modalName: 'PAYMENT_DIALOG' }))
    dispatch(removeDialogByName({ modalName: 'PAYMENT_CVV_DIALOG' }))
    dispatch(removeDialogByName({ modalName: 'PAYMENT_THREE_DS_DIALOG' }))
    // don't show rescue providers dialog on desktop and if all rescue providers are apple pay or google pay
    if (!isMobile && providers.every((provider) => ApplePayGooglePayProviders.includes(provider as RescueProviders))) {
      dispatch(openSnackbar({ message: errorMessage }))
    } else {
      dispatch(showResquePaymentMethod(true))
      dispatch(
        openDialog({
          modalName: 'PAYMENT_DIALOG',
          dialogProps: {
            code,
            rescueProviders: {
              errorMessage,
              providers,
            },
            loadPaymentData,
          },
        })
      )
    }
  }

export const handleFinishedPaymentFlowAction =
  ({ transactionId }: { transactionId: string }): TypedThunk =>
  (dispatch, getState) => {
    const dialogs = getDialogStackSelector(getState())
    const confirmationSuccessMessage = dialogs?.find?.(
      // @ts-ignore
      (el) => el.modalName === 'ORDER_CONFIRMATION_MESSAGE' && el.dialogProps?.status === 'success'
    )

    dispatch(clearPaymentState())

    if (!confirmationSuccessMessage) {
      dispatch(
        replaceDialog({
          modalName: 'ORDER_CONFIRMATION_MESSAGE',
          dialogProps: { status: 'waiting', transactionId },
        })
      )
    }
  }

export const handleFinishedQuickPurchasePaymentFlowAction =
  (res: CreateOrderResponse | ConfirmOrderResponse): TypedThunk =>
  (dispatch, getState) => {
    const offer = getOfferByCodeSelector(res.offer.code)(getState())

    dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
    dispatch(
      setQuickPurchaseStage({
        stage: 'success_purchase',
        params: { billingDescriptor: res.billingDescriptor, offer },
      })
    )
    dispatch(handleOfferQuickPurchaseSuccessAction(res as CreateOrderResponse & { method: PurchaseMethod }))
  }

export const handleOfferPurchaseSuccessAction =
  (
    response: (OfferPurchaseType | CreateOrderResponse) & { method: PurchaseMethod },
    isQuickPurchase?: boolean
  ): TypedThunk =>
  async (dispatch, getState, extraArg) => {
    const {
      provider,
      billingDescriptor,
      offer: { code },
      method,
    } = response
    const user = getUserSelector(getState())
    const userHash = getUserHashSelector(getState())
    const liveChatSettings = getLiveChatSettingsSelector(getState())
    const offer = getOfferByCodeSelector(code)(getState())

    await getOffers(true)(dispatch, getState, extraArg)

    if (!isQuickPurchase) {
      dispatch(removeDialogByName({ modalName: 'SHOP_DIALOG' }))
      dispatch(clearPaymentState())
      dispatch(
        replaceDialog({
          modalName: 'ORDER_CONFIRMATION_MESSAGE',
          dialogProps: {
            status: 'success',
            provider,
            billingDescriptor,
            method,
            offer,
            offerCode: code,
          },
        })
      )
    }

    dispatch(fetchSavedCards())
    dispatch(getAccountInfo())
    dispatch(setShowDailyBonusCaptcha({ shouldShow: false }))

    trackSuccessPayment(response, user, userHash)

    dispatch(setIsInsufficientFundsOffersAvailable(true))

    if (!liveChatSettings.showLiveChat) {
      dispatch(getLiveChatSettingsRequest())
    }

    if (response.isFirstDeposit) {
      if ('firstDeposit' in response) {
        dispatch(setFirstDepositDate({ firstDepositDate: response.firstDeposit }))
      }

      if ('firstDepositDate' in response) {
        dispatch(setFirstDepositDate({ firstDepositDate: response.firstDepositDate as string }))
      }

      dispatch(getAvailablePaymentProvidersRequest())

      trackFirstPurchase({
        amount: response.amount,
        currency: response.currency,
        offerCode: response.offer.code,
        city: response?.city || user?.kycInfo?.city || user?.softKycInfo?.city,
        zip: response?.zip || user?.kycInfo?.zip || user?.softKycInfo?.zip,
        external_id: userHash,
        transactionId: response.transactionId,
        value: response.amount,
        transactionTotal: response.offer.price,
        userId: user.id,
        provider: response.provider,
      })
    }

    if (window.location.href.includes('/play')) {
      dispatch(clearSlotGameFlow())
    }
  }

export const handleOfferQuickPurchaseSuccessAction =
  (response: CreateOrderResponse & { method: PurchaseMethod }): TypedThunk =>
  (dispatch, getState) => {
    const accountId = getUserAccountIdSelector(getState())

    void trackEvent('QuickPurchaseSuccessful', {
      category: response.offer.code,
      label: `Provider - ${response.provider}`,
      user_id: accountId,
      transactionTotal: response.offer.price,
      transactionId: response.transactionId,
    })

    dispatch(handleOfferPurchaseSuccessAction(response, true))
  }

export const handlePaymentErrorsHandlerAction =
  (error: WSResponse, isQuickPurchase?: boolean): TypedThunk =>
  (dispatch, getState) => {
    if (!error?.status) {
      if (process.env.ENABLE_LOG_MESSAGES) {
        Sentry.captureMessage(`non gateway error ${JSON.stringify(error)}`)
      }
    } else {
      const { status, body } = error
      const offerCode = sessionStorage?.getItem(OFFER_CODE_VERIFICATION) || null

      dispatch(clearPaymentState())

      if ('isFirstDeposit' in body && body.isFirstDeposit) {
        dispatch(getAvailablePaymentProvidersRequest())
      }

      let isOverLimit = false

      switch (body?.type) {
        case 'CreateOrderResponse': {
          void trackEvent('submitted_payment_error', {
            category: 'CreateOrderRequest',
            label: status?.errorCode || status?.errorText,
          })

          isOverLimit = body?.offer?.price > Number(body?.limitAvailable)

          if (isOverLimit) {
            dispatch(
              openDialog({
                modalName: 'PURCHASE_LIMIT_DIALOG',
                dialogProps: {
                  limitAmount: body.limitAmount,
                  limitAvailable: body.limitAvailable,
                  limitPeriod: body.limitPeriod,
                  limitEnd: body.limitEnd,
                },
              })
            )
          }

          if (body.requestKyc) {
            dispatch(openDialog({ modalName: 'GET_VERIFIED_DIALOG' }))
          }

          // [FYI]: Update payment methods even if the Payper provider catches an error (the method is saved)
          if (body.provider === WithdrawMethodType.PAYPER) {
            dispatch(fetchSavedCards())
          }

          break
        }
        case 'RepeatPaymentOrderResponse': {
          void trackEvent('submitted_payment_error', {
            category: 'RepeatPaymentOrderResponse',
            label: 'RepeatPaymentOrderResponse',
          })

          break
        }
        default: {
          // eslint-disable-next-line
          console.log('payment error', error)
        }
      }

      if (status?.errorText) {
        if (status?.errorText?.includes?.('Transaction already processed')) {
          const dialogs = getDialogStackSelector(getState()).map((el) => el.modalName)

          Sentry.captureMessage(`Transaction already processed, dialog: ${JSON.stringify(dialogs)}`)
          setClarity('duplicate_transaction', `Transaction already processed req: ${body.type}`)
        }

        if (isQuickPurchase) {
          if (body?.type === 'CreateOrderResponse' && (body.limitAmount || body.requestKyc)) {
            dispatch(setQuickPurchaseStage({ stage: 'quick_purchase', params: null })) // [FYI]: reset quick purchase flow
            dispatch(openSnackbar({ message: status.errorText }))
          } else {
            dispatch(setQuickPurchaseStage({ stage: 'failed_purchase', params: { error: status.errorText } }))
          }
        } else {
          // 'err_kyc_required' / 'err_kyc_required_low_risk' / 'err_kyc_required_high_risk'
          const shouldShowSnackbar = !status?.errorCode.startsWith('err_kyc_required') && !isOverLimit
          const shouldRedirectToPurchase = !['err_not_found', 'err_payment_in_progress'].includes(status?.errorCode)
          const isMobileApp = !!window.ReactNativeWebView
          if (shouldShowSnackbar) {
            if (body?.type === 'CreateOrderResponse' && status?.errorCode === 'err_insufficient_funds') {
              dispatch(
                openDialog({
                  modalName: 'INSUFFICIENT_FUNDS',
                  dialogProps: {
                    message: status.errorText,
                    offerCode: body?.offer?.code,
                  },
                })
              )
            } else if (
              body.type === 'CreateOrderResponse' &&
              body?.error?.rescueProviders?.length > 0 &&
              !isMobileApp
            ) {
              dispatch(setIsInsufficientFundsOffersAvailable(true))
              dispatch(showRescueProviders(body?.offer?.code, status.errorText, body?.error?.rescueProviders, false))
            } else if (shouldRedirectToPurchase) {
              dispatch(setIsInsufficientFundsOffersAvailable(true))
              dispatch(
                openSnackbar({
                  message: RepeatPaymentSnackbar,
                  componentProps: {
                    message: status.errorText,
                    offerCode,
                    isModalLike: true,
                  },
                  errorDetails: { errorCode: status?.errorCode },
                  autoHide: 100000,
                  //@ts-expect-error types issue
                  action: {
                    action() {
                      if (isMobileApp) {
                        window.ReactNativeWebView.postMessage(
                          '{ "shouldClose": true, "status": "failed", "type": "mobileAppPurchase" }'
                        )
                      }
                    },
                  },
                })
              )
            } else {
              dispatch(
                openSnackbar({
                  message: status.errorText,
                  componentProps: {
                    title: `dialogPaymentError.${status.errorCode}_title`,
                  },
                  errorDetails: { errorCode: status?.errorCode },
                  autoHide: 15000,
                })
              )
            }
          }
        }
      }
    }
  }
export const handleQuickPurchasePaymentErrorsHandlerAction =
  (error: WSResponse): TypedThunk =>
  (dispatch, getState) => {
    const { body = {}, status } = error
    const accountId = getUserAccountIdSelector(getState())

    void trackEvent('QuickPurchaseFailed', {
      category: 'offerCode' in body ? body?.offerCode : (body as CreateOrderResponse)?.offer?.code || 'Empty offerCode',
      label: `Provider - ${(body as CreateOrderResponse)?.provider || 'empty'}`,
      user_id: accountId,
      transactionTotal: 'price' in body ? body?.price : (body as CreateOrderResponse)?.offer?.price || 'empty',
      error: status?.errorCode || status?.errorText,
    })

    dispatch(handlePaymentErrorsHandlerAction(error, true))
  }

export const handlePurchaseResponseListenerByRouterAction =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const decodeSearch = window.location.search?.replace(/&amp;/gi, '&')
    const query = new URLSearchParams(decodeSearch)
    const user = getUserSelector(getState())
    const userHash = getUserHashSelector(getState())
    const liveChatSettings = getLiveChatSettingsSelector(getState())

    // [FYI]: for Payper provider payment_status='success' for all the time; pay attention about it
    if (query.get('payment_status')) {
      if (query.get('payment_status') === 'success') {
        if (
          query.get('transaction_id') &&
          query.get('amount') &&
          query.get('description') &&
          query.get('offer') &&
          query.get('currency')
        ) {
          const transactionId = query.get('transaction_id')
          const data: GetPaymentOrderRequest = { type: 'GetPaymentOrderRequest', transactionId }

          gateway
            .emit<GetPaymentOrderResponseBody>(data)
            .then(
              ({
                provider = '',
                success = false,
                status = '',
                failReason = 'Payment has failed',
                billingDescriptor,
                offer,
                method,
              }) => {
                // temp solution, remove once BE sent correct offer data in response (GC, SC)
                const offerFromStorage = getDataFromSessionStorage(OFFER_TEMP)
                const offerToDispatch = offerFromStorage || offer

                if (success) {
                  const response = {
                    provider: query.get('originalProvider'),
                    transactionId,
                    currency: query.get('currency'),
                    offer: {
                      price: Number(query.get('amount')),
                      title: query.get('description'),
                      code: query.get('offer'),
                    },
                    city: user?.kycInfo?.city || user?.softKycInfo?.city,
                    zip: user?.kycInfo?.zip || user?.softKycInfo?.zip,
                  }

                  trackSuccessPayment(response, user, userHash)

                  dispatch(
                    replaceDialog({
                      modalName: 'ORDER_CONFIRMATION_MESSAGE',
                      dialogProps: {
                        status: 'success',
                        provider,
                        billingDescriptor,
                        method,
                        offer: offerToDispatch,
                        transactionId,
                        offerCode: offer?.code,
                      },
                    })
                  )

                  // temp solution, remove once BE sent correct offer data in response (GC, SC)
                  removeDataFromSessionStorage(OFFER_TEMP)

                  if (!liveChatSettings.showLiveChat) {
                    dispatch(getLiveChatSettingsRequest())
                  }

                  if (query.get('isFirstDeposit')) {
                    dispatch(getAvailablePaymentProvidersRequest())

                    trackFirstPurchase({
                      amount: response.offer.price,
                      currency: response.currency,
                      offerCode: response.offer.code,
                      city: response.city,
                      zip: response.zip,
                      external_id: userHash,
                      transactionId: response.transactionId,
                      value: response.offer.price,
                      transactionTotal: response.offer.price,
                      userId: user.id,
                      provider: response.provider,
                    })
                  }
                } else if (provider === WithdrawMethodType.PAYPER && status === 'failed') {
                  dispatch(removeDialogByName({ modalName: 'ORDER_CONFIRMATION_MESSAGE' }))
                  dispatch(removeDialogByName({ modalName: 'PAYMENT_DIALOG' }))
                  dispatch(removeDialogByName({ modalName: 'PAYMENT_CVV_DIALOG' }))
                  dispatch(removeDialogByName({ modalName: 'PAYMENT_THREE_DS_DIALOG' }))
                  dispatch(openSnackbar({ message: failReason }))
                }
              }
            )
            .catch((err) => {
              dispatch(errorHandler(err, data))
            })
        }

        dispatch(replaceDialog({ modalName: 'ORDER_CONFIRMATION_MESSAGE', dialogProps: { status: 'waiting' } }))
      } else if (query.get('payment_status') === 'cancelled') {
        const transactionId = query.get('transaction_id')
        const data: GetPaymentOrderRequest = { type: 'GetPaymentOrderRequest', transactionId }
        const isMobileApp = !!window.ReactNativeWebView

        gateway
          .emit<GetPaymentOrderResponseBody>(data)
          .then((res) => {
            if (res?.error?.rescueProviders?.length > 0 && !isMobileApp) {
              dispatch(showRescueProviders(res?.offer?.code, res?.failReason, res?.error?.rescueProviders))
            } else {
              dispatch(
                replaceDialog({
                  modalName: 'ORDER_CONFIRMATION_MESSAGE',
                  dialogProps: {
                    status: 'cancelled',
                    provider: query.get('originalProvider') || null,
                    offerCode: res?.offer?.code,
                  },
                })
              )
            }
          })
          .catch((err) => {
            dispatch(errorHandler(err, data))
          })
      }

      window.history.replaceState({}, document.title, window.location.href.replace(window.location.search, ''))
    }
  }

export const handleOfferDeclineNotificationHandlerAction =
  ({
    reason,
    rescueProviders,
    offer,
    internalErrorCode,
  }: OfferDeclineNotification & {
    // TODO: fix type
    internalErrorCode?: string
  }): TypedThunk =>
  (dispatch) => {
    dispatch(fetchSavedCards())
    dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))

    trackEvent('submitted_payment_error', { category: 'CreateOrderRequest', label: reason })

    dispatch(removeDialogByName({ modalName: 'ORDER_CONFIRMATION_MESSAGE' }))
    dispatch(removeDialogByName({ modalName: 'PAYMENT_DIALOG' }))
    dispatch(removeDialogByName({ modalName: 'PAYMENT_CVV_DIALOG' }))
    dispatch(removeDialogByName({ modalName: 'PAYMENT_THREE_DS_DIALOG' }))

    const isMobileApp = !!window.ReactNativeWebView

    if (internalErrorCode === 'err_insufficient_funds') {
      dispatch(
        openDialog({
          modalName: 'INSUFFICIENT_FUNDS',
          dialogProps: {
            message: reason,
            offerCode: offer?.code,
          },
        })
      )
    } else if (rescueProviders?.length > 0 && !isMobileApp) {
      dispatch(setIsInsufficientFundsOffersAvailable(true))
      dispatch(showRescueProviders(offer.code, reason, rescueProviders))
    } else {
      dispatch(setIsInsufficientFundsOffersAvailable(true))
      dispatch(openSnackbar({ message: reason || 'Payment has failed' }))
    }

    return null
  }

export const fetchPaymentData = (): TypedThunk => (dispatch, getState) => {
  const purchaseProviders = getPurchaseProvidersSelector(getState())
  const paymentMethods = getPaymentMethodsSelector(getState())

  if (!paymentMethods) {
    dispatch(fetchSavedCards())
  }

  if (!purchaseProviders) {
    dispatch(getAvailablePaymentProvidersRequest())
  }
}

export const handleOpenPaymentProvidersDialogAction =
  (offerCode: string): TypedThunk =>
  (dispatch) => {
    dispatch(fetchPaymentData())

    // pay with card
    // skrill
    dispatch(openDialog({ modalName: 'PAYMENT_DIALOG', dialogProps: { code: offerCode } }))
  }

export const handleBuyShopOfferAction =
  ({ offer, feature = 'pop_up', routerPush }: BuyShopOfferProps): TypedThunk =>
  (dispatch, getState) => {
    const purchaseLimits = purchaseLimitsSelector(getState())
    const isSkipOtp = skipOtpSelectorWithMandatoryAndClosed(getState())
    const isLoggedIn = isLoggedInSelector(getState())
    const userRestrictions = getUserRestrictionsSelector(getState())
    const sweepstakeEnabled = sweepstakeEnabledSelector(getState())

    trackClickOnOffer({ offer, mode: sweepstakeEnabled ? 'SC' : 'GC', feature, page_path: window.location.pathname })

    if (!isLoggedIn) {
      dispatch(closeAllDialogs())
      routerPush(ROUTES.REGISTER)

      return null
    }

    if (!isSkipOtp) {
      dispatch(openDialog({ modalName: 'PHONE_VERIFICATION_DIALOG' }))

      return null
    }

    if (userRestrictions.includes('no_purchase')) {
      dispatch(openDialog({ modalName: 'RESTRICT_USER_DIALOG' }))

      return null
    }

    // check for purchase limits
    if (purchaseLimits && Number(purchaseLimits.limitAvailable) < offer.price) {
      dispatch(openDialog({ modalName: 'PURCHASE_LIMIT_DIALOG', dialogProps: { ...purchaseLimits } }))

      return null
    }

    dispatch(handleOpenPaymentProvidersDialogAction(offer.code))

    return null
  }

export const handleCreateOrderResponseCardPaymentErrorHandlerAction =
  (props: CreateOrderResponseCardPaymentErrorProps): TypedThunk =>
  async (dispatch) => {
    const { error, options } = props

    if (error?.status?.errorCode === 'err_payment_input_cvv') {
      dispatch(removeDialogByName({ modalName: 'SHOP_DIALOG' }))
      dispatch(removeDialogByName({ modalName: 'PAYMENT_DIALOG' }))
      dispatch(removeDialogByName({ modalName: 'PAYMENT_THREE_DS_DIALOG' }))
      dispatch(
        openDialog({
          modalName: 'PAYMENT_CVV_DIALOG',
          dialogProps: {
            ...(options.isCustomProvider ? { provider: options.provider } : {}),
            cardToken: options.paymentMethodId,
            offerCode: options.offerCode,
            sourceId: error.body.sourceId,
          },
        })
      )
    } else if (error?.status?.errorCode === 'err_payment_routing') {
      const seonSessionId = await getSeonSessionId()

      dispatch(repeatPaymentAfterErrorByProvider({ ...props, seonSessionId }))
    } else if (error?.status?.errorCode === 'err_payment_3ds_required') {
      const seonSessionId = await getSeonSessionId()
      dispatch(repeatPaymentAfterErrorByProvider({ ...props, seonSessionId, isThreeDSRequired: true }))
    } else {
      dispatch(paymentErrorsHandler(error))
      dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
    }
  }

export const handleCreateOrderResponseCardQuickPurchasePaymentErrorHandlerAction =
  (props: CreateOrderResponseCardPaymentErrorProps): TypedThunk =>
  async (dispatch, getState) => {
    const { error, options } = props
    const accountId = getUserAccountIdSelector(getState())
    const errorCode = error?.status?.errorCode

    const trackErrorEvent = () => {
      void trackEvent('QuickPurchaseFailed', {
        category: options.offerCode,
        label: `Provider - ${error.body.provider || 'empty'}`, // [FYI]: missing - get from BE as ""
        user_id: accountId,
        transactionTotal: error.body.price,
        error: errorCode || error?.status?.errorText,
      })
    }

    if (errorCode === 'err_payment_input_cvv') {
      trackErrorEvent()
      dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
      dispatch(removeDialogByName({ modalName: 'QUICK_PURCHASE_DIALOG' }))
      dispatch(setQuickPurchaseStage({ stage: 'quick_purchase' }))
      dispatch(
        openDialog({
          modalName: 'PAYMENT_CVV_DIALOG',
          dialogProps: {
            ...(options.isCustomProvider ? { provider: options.provider } : {}),
            cardToken: options.paymentMethodId,
            offerCode: options.offerCode,
            sourceId: error.body.sourceId,
          },
        })
      )
    } else if (errorCode === 'err_payment_routing') {
      trackErrorEvent()

      const seonSessionId = await getSeonSessionId()

      dispatch(repeatPaymentAfterErrorByProvider({ ...props, seonSessionId, isQuickPurchase: true }))
    } else if (error?.status?.errorCode === 'err_payment_3ds_required') {
      const seonSessionId = await getSeonSessionId()
      dispatch(
        repeatPaymentAfterErrorByProvider({ ...props, seonSessionId, isThreeDSRequired: true, isQuickPurchase: true })
      )
    } else {
      dispatch(quickPurchasePaymentErrorsHandler(error))
      dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
    }
  }

export const handle3dsFlowStart =
  (threeDsData: ThreeDSData): TypedThunk =>
  (dispatch) => {
    dispatch(setIsPaymentFlowInProcess({ isPaymentFlowInProcess: false }))
    dispatch(closeAllDialogs())
    dispatch(openDialog({ modalName: 'PAYMENT_THREE_DS_DIALOG', dialogProps: threeDsData }))
  }
