import { SnackbarContent } from '@patrianna/core-components'
import cx from 'classnames'
import { useReturnToCashier } from 'hooks/useReturnToCashier'

import AnimatedOverlay from 'dialogs/components/AnimatedOverlay'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { closeSnackbar } from 'store/modules/snackbar/actions'
import { getSnackbarMessageAlignSelector, getSnackbarVariantSelector } from 'store/modules/snackbar/selectors'

import RichMessage from './RichMessage'
import classes from './styles.module.scss'

export interface SnackbarProps {
  className?: string
  message?: string
  offerCode: string
}

function RepeatPaymentSnackbar(props: SnackbarProps) {
  const { message, offerCode, ...other } = props

  const variant = useAppSelector(getSnackbarVariantSelector)
  const messageAlign = useAppSelector(getSnackbarMessageAlignSelector)
  const handleRepeatPayment = useReturnToCashier()

  const dispatch = useAppDispatch()

  const handleOverlayClick = () => {
    handleRepeatPayment?.(offerCode)
    dispatch(closeSnackbar())
  }

  return (
    <AnimatedOverlay mobileDirection='center' desktopDirection='center' overlayHandler={handleOverlayClick}>
      <SnackbarContent
        className={cx(classes[messageAlign], classes.modalLikeSnackbarContent)}
        message={<RichMessage variant={variant} message={message} action={() => handleRepeatPayment?.(offerCode)} />}
        {...other}
      />
    </AnimatedOverlay>
  )
}

export default RepeatPaymentSnackbar
