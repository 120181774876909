import { setClarity } from '@patrianna-payments/analytics/clarity/events'
import { graphyteTrack } from '@patrianna-payments/analytics/graphyte/events'
import { formatISO } from 'date-fns'
import type { ShopOffer } from '@patrianna/shared-patrianna-types/store/ShopModule'
import type { User } from '@patrianna/shared-patrianna-types/store/UserModule'
import type { FeatureProps } from '@patrianna-payments/shared-store/payments/types'
import { v4 as uuid } from 'uuid'
import BuilderEventService from 'analyticActions/builder/events'
import { trackEvent } from 'config/analytic'
import { trackOptimizelyEvent } from '@patrianna-payments/analytics/optimizely/events'
import type { AnalyticsSignUpParams } from 'analyticActions/common/types'

type Offer = {
  price: number
  title: string
  code: string
}

type PaymentResponse = {
  currency: string
  offer: Offer
  provider: string
  transactionId: string
  city?: string
  zip?: string
}

type MiniGame = {
  code: string
  title: string
  currency: string
  gameType: string
}

export type MiniGameUser = {
  purchaserUser: number
  userId: string
}

export const trackSuccessPayment = (response: PaymentResponse, user: User, userHash: string) => {
  const eventData = {
    event: 'purchase',
    transactionId: response.transactionId,
    transactionAffiliation: response.provider,
    transactionTotal: response.offer.price,
    transactionTax: 0,
    transactionShipping: 0,
    transactionProducts: [
      {
        sku: response.offer.code,
        name: response.offer.title,
        category: `Purchase $${response.offer.price}`,
        price: response.offer.price,
        quantity: 1,
      },
    ],
    city: response?.city || user?.kycInfo?.city || user?.softKycInfo?.city || '',
    zip: response?.zip || user?.kycInfo?.zip || user?.softKycInfo?.zip || '',
    external_id: userHash,
  }

  trackEvent(
    'purchase',
    {
      ...eventData,
      currency: response.currency,
      value: response.offer.price,
      content_id: response.transactionId,
      event_id: response.transactionId,
    },
    { tiktok: true, bloomreach: false }
  )
  trackOptimizelyEvent('purchase', {
    revenue: Number(response.offer.price),
    packageName: response.offer.title,
    provider: response.provider,
    account_id: user.id,
  })

  trackEvent('submitted_payment_success', { category: response.provider, label: response.offer.code })
}

export const trackSignUp = ({ userId, method, country, state, mode, utm, referredBy }: AnalyticsSignUpParams) => {
  trackEvent(
    'sign_up',
    {
      category: 'Sign up',
      label: method || 'Guest',
      country,
      state,
      mode,
      utm,
      referredBy,
      event_id: userId.split('/')[0],
    },
    { tiktok: true }
  )
  trackOptimizelyEvent('signUp', { method: method || 'manual', account_id: userId })
  setClarity('Sign up', 'sign_up')
  BuilderEventService.trackConversion(0)
}

export const trackLogin = (method?: string) => {
  trackEvent('login', { category: 'Login', label: method || 'Guest' })
  BuilderEventService.trackEvent('login')
}

export const trackClickOnOffer = ({
  offer,
  feature,
  mode,
  page_path,
}: {
  offer: ShopOffer
  feature: FeatureProps
  mode: 'SC' | 'GC'
  page_path: string
}) => {
  trackEvent('clicked_on_offer', {
    category: `package_${offer?.price}`,
    label: feature,
    mode,
    page_path,
  })

  BuilderEventService.trackSessionEvent('click_offer_per_session')
}

export const trackOpenMiniGame = (
  game: MiniGame,
  user: MiniGameUser,
  graphyteEnabled?: boolean,
  balance?: { [key: string]: number }
) => {
  trackEvent(`open_mini_game_${game.currency.toLowerCase()}`, {
    category: game.title,
    label: game.code,
  })
  trackEvent(
    'open_mini_game',
    {
      code: game.code,
      name: game.title,
      currency: game.currency,
      type: game.gameType,
      ...balance,
    },
    { all: false, bloomreach: true }
  )
  if (graphyteEnabled) {
    graphyteTrack('GAME_PLAY', {
      event_type: 'Gaming',
      event_uuid: uuid(),
      event_datetime: formatISO(new Date()),
      event_value: user.purchaserUser,
      event_info_1: game.code,
      event_info_2: game.title,
      event_info_3: game.gameType,
      userid: user.userId,
      event_ccy: game.currency,
    })
  }
}

export const trackCloseMiniGame = (
  game: MiniGame,
  user: MiniGameUser,
  graphyteEnabled?: boolean,
  balance?: { [key: string]: number }
) => {
  trackEvent(`close_mini_game_${game.currency.toLowerCase()}`, {
    category: game.title,
    label: game.code,
  })
  trackEvent(
    'close_mini_game',
    {
      code: game.code,
      name: game.title,
      currency: game.currency,
      type: game.gameType,
      ...balance,
    },
    { all: false, bloomreach: true }
  )
  if (graphyteEnabled) {
    graphyteTrack('GAME_CLOSE', {
      event_type: 'Gaming',
      event_uuid: uuid(),
      event_datetime: formatISO(new Date()),
      event_value: user.purchaserUser,
      event_info_1: game.code,
      event_info_2: game.title,
      event_info_3: game.gameType,
      userid: user.userId,
      event_ccy: game.currency,
    })
  }
}
