import type { PaymentRemoteComponentProps } from '@patrianna-payments/payment-remote-entry'
import { PaymentRemoteEntry } from '@patrianna-payments/payment-remote-entry'
import { captureException } from '@sentry/nextjs'

import { useTranslation } from 'app/context/translations'
import deps from 'package.json'
import { getTemporaryToken } from 'services/tokens/api'
import { serviceDependencies } from 'src/services'

const url = process.env.PAYMENT_MF_HOST

const RemoteEntryPayment = <T extends Record<string, unknown> = Record<string, unknown>>(
  props: PaymentRemoteComponentProps<T>
) => {
  const t = useTranslation()

  const handleError = (error: any) => {
    if (process.env.PROFILE === 'prod') {
      captureException({
        title: '[PAYMENT REMOTE ENTRY]',
        message: error,
      })
    }
    console.error('[PAYMENT REMOTE ENTRY] - script loading error', error)
  }

  const onModuleLoadError = (error: any) => {
    if (process.env.PROFILE === 'prod') {
      captureException({
        title: '[PAYMENT MODULE LOADING ERROR]',
        message: error,
      })
    }
    console.error("[PAYMENT MODULE LOADING ERROR] - can't find module", error)
  }

  return (
    <PaymentRemoteEntry
      brandName='payment'
      t={t}
      dependencies={deps.dependencies}
      onScriptLoadError={handleError}
      onModuleLoadError={onModuleLoadError}
      errorHandler={serviceDependencies.errorHandler}
      url={url}
      ssr={false}
      getTemporaryToken={getTemporaryToken}
      fraud={serviceDependencies.fraud}
      payment={serviceDependencies.payment}
      {...props}
    />
  )
}

export default RemoteEntryPayment
