import type {
  Providers,
  ProviderSettingsMapType,
  RedeemPolicyField,
} from '@patrianna-payments/shared-store/redeem/types'
import type { RedeemLimitPolicy } from 'temp/payment/client'
import { currencyFormatter } from '@patrianna/shared-utils/currency'

export type ValidateRedeemAmountType = {
  generalRedeemPolicy: {
    minAmount: number
    maxAmount: number
  }
  providerRedeemPolicy: RedeemPolicyField | null
  balance: number
  amount: number
}

export type ValidateRedeemAmountByMultiCurrencyType = Omit<
  ValidateRedeemAmountType,
  'providerRedeemPolicy' | 'generalRedeemPolicy'
> & {
  providerPolicy: RedeemLimitPolicy | null
  currency: string
  amountByRate: number
  country: string
  state?: string
}

// mock t function for parsing in mf dialogs
const t = (key: string) => `$$${key.length}:${key}`

/*
 * balance - in SC
 * amount - in SC
 * providerPolicy
 *   min - in SC
 *   max - in current currency
 *   available - in current currency
 * */
export const validateRedeemAmountByMultiCurrency = ({
  balance,
  amount,
  providerPolicy,
  country,
  currency,
  state,
  amountByRate,
}: ValidateRedeemAmountByMultiCurrencyType) => {
  if (!amount || !amountByRate) {
    return t('errors.please_input_redeemable_amount')
  }

  const getFormatted = (value: number) => `${currency}${currencyFormatter(country, { currency })(value)}`

  if ('available' in providerPolicy) {
    const stateValue = state ? `${t('errors.word_in')} ${t(`countries.country_${country}_${state}`)}` : ''
    const dailyLimit = getFormatted(providerPolicy?.max)
    const dailyLimitPart1 = `${t('errors.daily_redemption_limit')} ${stateValue} ${t('errors.word_is')} ${dailyLimit}.`

    if (providerPolicy?.available === 0) {
      return `${dailyLimitPart1} ${t('errors.reached_the_maximum_redemption')}`
    }

    if (amountByRate > providerPolicy?.available) {
      const availableLimit = getFormatted(providerPolicy?.available)

      return `${dailyLimitPart1} ${t('errors.maximum_amount_available_to_redeem')} ${availableLimit}.`
    }
  }

  if (amount < providerPolicy?.min) {
    return t('errors.low_redeemable_sweepstakes_coins')
  }

  if (amount > balance) {
    return t('errors.do_not_have_enough_redeemable_sc')
  }

  if (amountByRate > providerPolicy?.max) {
    return `${t('errors.new_amount_to_redeem_more_than_max')} ${getFormatted(providerPolicy?.max)}`
  }

  return null
}

/**
 * Deprecated
 * Will remove
 */
export const validateRedeemAmount = ({
  generalRedeemPolicy,
  balance,
  amount,
  providerRedeemPolicy,
}: ValidateRedeemAmountType) => {
  if (!amount) {
    return t('errors.please_input_redeemable_amount')
  }

  if (
    providerRedeemPolicy &&
    'availableToRedeem' in providerRedeemPolicy &&
    amount > providerRedeemPolicy.availableToRedeem
  ) {
    return `${t('errors.available_to_redeem_part1')}${providerRedeemPolicy.availableToRedeem.toFixed(2)} ${t(
      'available_to_redeem_part2'
    )}`
  }

  if (amount < generalRedeemPolicy.minAmount || (providerRedeemPolicy && amount < providerRedeemPolicy.minAmount)) {
    return t('errors.low_redeemable_sweepstakes_coins')
  }

  if (amount > balance) {
    return t('errors.do_not_have_enough_redeemable_sc')
  }

  if (providerRedeemPolicy && amount > providerRedeemPolicy.maxAmount) {
    return `${t('errors.amount_to_redeem_is_more_than_max')} ${providerRedeemPolicy.maxAmount.toFixed(2)}`
  }

  if (amount > generalRedeemPolicy.maxAmount) {
    return `${t('errors.amount_to_redeem_is_more_than_max')} ${generalRedeemPolicy.maxAmount.toFixed(2)}`
  }

  return null
}

export const providerToProviderNameMap: Partial<Record<Providers, keyof ProviderSettingsMapType>> = {
  ACH: 'nuvei',
  Skrill: 'skrill',
  MasspayACH: 'masspay',
  Trustly: 'trustly',
  Payper: 'payper',
  StandardACH: 'standardAch',
}
