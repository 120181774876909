export type PaymentTypes =
  | 'ROOT_SCREEN'
  | 'WITH_CARD'
  | 'PAY_ADYEN'
  | 'PAY_FISERV'
  | 'PAY_SPREEDLY'
  | 'PAY_SPREEDLY_RAPYD'
  | 'PAY_SPREEDLY_FISERV'
  | 'PAY_MAZOOMA'
  | 'PAY_CARD'
  | 'PAY_SPREEDLY_FISERV_2'
  | 'PAY_SPREEDLY_EMERCHANTPAY'
  | 'PAY_QA_DEV'
  | 'PAY_PAYPER'

export enum RescueProviders {
  Spreedly = 'spreedly',
  SpreedlyApplePay = 'spreedly_apple_pay',
  SpreedlyGooglePay = 'spreedly_google_pay',
  Trustly = 'trustly',
  Skrill = 'skrill',
  Nuvei = 'nuvei_mazooma_ach',
  FiservApplePay = 'fiserv_apple_pay',
  FiservGooglePay = 'fiserv_google_pay',
  Payper = 'payper',
  Fiserv = 'fiserv',
}

export const ApplePayGooglePayProviders = [
  RescueProviders.SpreedlyApplePay,
  RescueProviders.SpreedlyGooglePay,
  RescueProviders.FiservApplePay,
  RescueProviders.FiservGooglePay,
]
