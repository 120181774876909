import { Currency } from '@patrianna-payments/shared-store/redeem/types/Currencies'
import type { ConfirmRedeemHandlerArgs, RedeemMoneyArgs } from '@patrianna-payments/shared-store/redeem/store/actions'
import {
  confirmRedeemHandler as confirmRedeemHandlerBase,
  redeemFlowStart as redeemFlowStartBase,
  redeemMoney as redeemMoneyBase,
  resolveRightRedeemWay as resolveRightRedeemWayBase,
} from '@patrianna-payments/shared-store/redeem/store/actions'
import {
  getRedeemPolicyByCurrencySelector,
  getSCProvidersRedeemPoliciesSelector,
  getIsNewRedeemPolicyEnabledSelector,
  getProviderSettingsMapSelector,
} from '@patrianna-payments/shared-store/redeem/store/selectors'
import type { Providers, ProvidersData } from '@patrianna-payments/shared-store/redeem/types'
import type { WithdrawPaymentProviders } from '@patrianna-payments/shared-store/redeem/types/withdrawPaymentProviders'
import type { TypedDispatch, TypedThunk } from 'src/store'
import {
  providerToProviderNameMap,
  validateRedeemAmount,
  validateRedeemAmountByMultiCurrency,
} from 'src/store/modules/redeem/utils'
import { openDialog } from 'store/modules/dialog/actions'
import { getIsSoftKycRequiredEnabledSelector, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { cancelRedeemRequest } from '@patrianna-payments/shared-store/redeem/store/actions'
import { getBalanceByIDSelector } from 'store/modules/currencies/selectors'
import {
  KYCinReviewSelector,
  emailHiddenSelector,
  getIsSoftKYCAvailableSelector,
  getUserRealEmailSelector,
  getUserRestrictionsSelector,
  isGuestModeSelector,
  isKYCRequiredSelector,
  getUserAccountIdSelector,
  getCurrencyDataFromUserPersonalInfoSelector,
} from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
import { trackEvent } from 'config/analytic'
import {
  getWithdrawProvidersSelector,
  getPurchaseProvidersSelector,
  getCurrencyRateSelector,
} from '@patrianna-payments/shared-store/payments/store/selectors'
import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import { getValueWithRate } from 'dialogs/RedeemFlow/utils'

export const redeemFlowStart =
  (pushRouter: (route: string) => void): TypedThunk =>
  (dispatch: TypedDispatch, getState) => {
    const isGuestMode = isGuestModeSelector(getState())
    const isEmailHidden = emailHiddenSelector(getState())
    const userRestrictions = getUserRestrictionsSelector(getState())
    const isSoftKYCEntered = getIsSoftKYCAvailableSelector(getState())
    const isSoftKYCRequired = getIsSoftKycRequiredEnabledSelector(getState())
    const { currency = 'USD' } = getCurrencyDataFromUserPersonalInfoSelector(getState())
    const sweepstakeEnabled = sweepstakeEnabledSelector(getState())

    const purchaseProviders = getPurchaseProvidersSelector(getState())
    const redeemProviders = getWithdrawProvidersSelector(getState())

    dispatch(
      redeemFlowStartBase({
        isGuestMode,
        sweepstakeEnabled,
        isEmailHidden,
        userRestrictions,
        // TODO will remove after testing providerSettings from GetWithdrawSettingsRequest
        redeemProviders: !purchaseProviders ? null : (redeemProviders as unknown as WithdrawPaymentProviders),
        registerRoute: ROUTES.REGISTER,
        // a/b testing
        isSoftKYCAvailable: isSoftKYCRequired === null ? undefined : isSoftKYCEntered,
        pushRouter,
        currency,
        isUseWithdrawSettings: process.env.IS_USE_WITHDRAW_SETTINGS,
        isUseRedeemHistoryRequestForRedeemList: process.env.IS_USE_REDEEM_HISTORY_REQUEST_FOR_REDEEM_LIST_ITEMS,
      })
    )
  }

export const redeemMoney =
  (redeemFields: RedeemMoneyArgs['redeemFields'], seonSessionId: string): TypedThunk =>
  (dispatch: TypedDispatch) => {
    const notifyError = (message: string) => {
      dispatch(openDialog({ modalName: 'REDEEM_INFO_BALANCE_DIALOG', dialogProps: { message } }))
    }

    dispatch(redeemMoneyBase({ notifyError, redeemFields, seonSessionId }))
  }

export const resolveRightRedeemWay =
  (currency: Currency): TypedThunk =>
  (dispatch: TypedDispatch, getState) => {
    // TODO add type for all currencies
    const balance = getBalanceByIDSelector(getState(), currency as Currencies)
    const kycRequired = isKYCRequiredSelector(getState())
    const KYCinReview = KYCinReviewSelector(getState())

    const notifyError = () => dispatch(openDialog({ modalName: 'REDEEM_INFO_BALANCE_DIALOG' }))

    dispatch(resolveRightRedeemWayBase({ redeemable: balance.redeemable, KYCinReview, kycRequired, notifyError }))
  }

const allowedProviders: Providers[] = ['ACH', 'Skrill', 'MasspayACH', 'Payper', 'StandardACH']

export const redeemMoneyRequest =
  ({
    amounts,
    t,
    seonSessionId,
    additionalData: additionalDataProp = {},
  }: {
    amounts: ProvidersData
    t: (key: string) => string
    seonSessionId: string
    additionalData?: ConfirmRedeemHandlerArgs['additionalData']
  }): TypedThunk =>
  (dispatch: TypedDispatch, getState) => {
    const email = getUserRealEmailSelector(getState())
    const balance = getBalanceByIDSelector(getState(), Currency.SC)
    const providersRedeemPolicy = getSCProvidersRedeemPoliciesSelector(getState(), allowedProviders)
    const redeemPolicy = getRedeemPolicyByCurrencySelector(getState(), Currency.SC)
    const isNewRedeemPolicyEnabled = getIsNewRedeemPolicyEnabledSelector(getState())
    const providerSettingsMap = getProviderSettingsMapSelector(getState())
    const { currency = 'USD', country = 'US', state } = getCurrencyDataFromUserPersonalInfoSelector(getState())
    const currencyRate = getCurrencyRateSelector(getState())

    dispatch(
      confirmRedeemHandlerBase({
        amounts,
        currency: balance.currency as Currency,
        seonSessionId,
        email,
        notify: (amount: number, providerType: string) => {
          if (amounts?.Payper) {
            return dispatch(openDialog({ modalName: 'CONFIRM_PAYPER_DATA_DIALOG', dialogProps: { amounts } }))
          }

          dispatch(
            openDialog({
              modalName: 'WITHDRAW_SWEEPSTAKE_DIALOG',
              dialogProps: { amount, withdrawMethod: { withdrawMethod: { type: providerType } } },
            })
          )
        },
        notifyError: (error: string) => {
          dispatch(openDialog({ modalName: 'REDEEM_INFO_BALANCE_DIALOG', dialogProps: { message: error } }))
        },
        validateRedeemAmount: (amount: number, provider: Providers) => {
          const baseProps = { balance: balance.redeemable, amount, t }

          if (isNewRedeemPolicyEnabled) {
            return validateRedeemAmountByMultiCurrency({
              ...baseProps,
              providerPolicy: providerSettingsMap?.[providerToProviderNameMap[provider]]?.limitPolicy || null,
              country,
              currency,
              state,
              amountByRate: getValueWithRate({ rate: currencyRate?.rate, amount }),
            })
          }

          return validateRedeemAmount({
            ...baseProps,
            generalRedeemPolicy: redeemPolicy,
            providerRedeemPolicy: providersRedeemPolicy[provider] || null,
          })
        },
        additionalData: {
          Payper: {
            email: additionalDataProp?.Payper?.email || '', // [FYI]: when used form to fill account data
            phone: additionalDataProp?.Payper?.phone || '',
          },
        },
      })
    )
  }

export const redeemTrackGAEvent =
  ({ event, options }: { event: string; options: { [key: string]: unknown } }): TypedThunk =>
  (_, getState) => {
    const userAccountId = getUserAccountIdSelector(getState())

    void trackEvent(event, { event, category: userAccountId, ...options })
  }

export const cancelRedeemGeneralFlow =
  (id: number): TypedThunk =>
  (dispatch) => {
    dispatch(cancelRedeemRequest(id, process.env.IS_USE_WITHDRAW_SETTINGS))
  }
